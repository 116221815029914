import './github/code-scanning-alerts-search-input'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'

function bothChecked(): boolean {
  const defaultSetup = document.getElementById('checkbox_code_scanning_default_setup_enabled') as HTMLInputElement
  const advancedSetup = document.getElementById('checkbox_code_scanning_advanced_setup_enabled') as HTMLInputElement

  return defaultSetup.checked && advancedSetup.checked
}

on('change', '.js-repo-features-code-scanning-enablement-form input[type=checkbox]', function ({currentTarget}) {
  if (bothChecked()) {
    return
  }
  const repoOption = currentTarget.closest<HTMLElement>('.js-repo-option')!
  const indicator = repoOption.querySelector<HTMLElement>('.js-status-indicator')!
  indicator.classList.remove('status-indicator-success', 'status-indicator-failed')
  indicator.classList.add('status-indicator-loading')

  currentTarget.closest<HTMLFormElement>('form')!.requestSubmit()
})

remoteForm('.js-repo-features-code-scanning-enablement-form', async function (form, wants) {
  let response
  try {
    response = await wants.html()
  } catch (error) {
    for (const el of form.querySelectorAll('.status-indicator-loading')) {
      el.classList.remove('status-indicator-loading')
      el.classList.add('status-indicator-failed')
      const option = el.closest<HTMLElement>('.js-repo-option')!
      const checkbox = option.querySelector<HTMLInputElement>('input[type=checkbox]')!
      checkbox.checked = !checkbox.checked
    }
    return
  }
  for (const el of form.querySelectorAll('.status-indicator-loading')) {
    el.classList.remove('status-indicator-loading')
    el.classList.add('status-indicator-success')
  }

  const existingNode = document.getElementById('js-code-scanning-codeql-messages')!
  const fragmentPath = existingNode.getAttribute('data-fragment-path')!
  const newNode = document.createElement('poll-include-fragment')

  newNode.id = existingNode.id
  newNode.setAttribute('data-fragment-path', fragmentPath)
  newNode.setAttribute('src', `${encodeURI(fragmentPath)}?wait_until=has_workflow_run`)
  existingNode?.replaceWith(newNode)

  document.querySelector<HTMLElement>('.js-repo-nav')!.replaceWith(response.html)
})

on('change', '.js-repo-features-code-scanning-default-setup-form input[type=checkbox]', function () {
  if (bothChecked()) {
    ;(document.getElementById('codeql-switch-to-default-setup-dialog') as HTMLDialogElement).showModal()
  }
})

on('change', '.js-repo-features-code-scanning-advanced-setup-form input[type=checkbox]', function () {
  if (bothChecked()) {
    ;(document.getElementById('codeql-switch-to-advanced-setup-dialog') as HTMLDialogElement).showModal()
  }
})
